import * as React from 'react';
import {
    Create,
    TextInput,
    required,
    SimpleForm,
    DateTimeInput,
    ReferenceInput,
    NullableBooleanInput,
    SelectInput, FormDataConsumer, BooleanInput, minValue
} from 'react-admin';
import HTMLEditorInput, { validHtml } from '../../common/inputs/HTMLEditorInput';
import { BatchScheduleSubjectChapterInput } from "../batch_schedules/BatchScheduleEdit";
import VideoAutocompleteInput from "../../common/inputs/VideoAutocompleteInput";
import ProductAutocompleteInputOld from "../../common/inputs/ProductAutocompleteInput-old";


const CouponCreate = () => {
    return (
        <Create redirect={'/ecom_coupons'}>
            <SimpleForm>
                <TextInput source="code" fullWidth validate={[required()]} />


                <BooleanInput defaultValue={true} source={'showDiscountInPercentage'} />
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => formData.showDiscountInPercentage ?
                            <>
                                <TextInput source="discount_in_percentage" prefix={'%'} type={'number'} validate={[minValue(0)]} fullWidth />
                                <TextInput source="max_discount_price" prefix={'₹'} type={'number'} validate={[minValue(0)]} fullWidth />
                            </> : <TextInput source="discount_in_price" prefix={'₹'} type={'number'} validate={[minValue(0)]} fullWidth />
                    }
                </FormDataConsumer>
                <TextInput source="max_total_usages" type={'number'} fullWidth />
                <TextInput source="max_usage_by_per_student" type={'number'} fullWidth />
                <ReferenceInput label="Category" source="category_id" reference="ecom_categories">
                    <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
                <ReferenceInput label="Product Template" source="product_template_id"
                    reference="ecom_product_templates">
                    <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
                {/*<FormDataConsumer>*/}
                {/*    {*/}
                {/*        ({formData, ...rest}) =><ProductAutocompleteInputOld formData={formData} fullWidth source={'product_template_id'}/>*/}
                {/*    }*/}
                {/*</FormDataConsumer>*/}
                <NullableBooleanInput source={'hidden'} fullWidth />
                <DateTimeInput source={'started_at'} validate={[required()]} fullWidth />
                <DateTimeInput source={'expiring_at'} fullWidth />
                <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]} />
            </SimpleForm>
        </Create>
    );
};

export default CouponCreate;

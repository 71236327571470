import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  TopToolbar,
  CreateButton,
  ExportButton,
  useRecordContext,
} from "react-admin";
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { NoDataSection } from "../../common/NoDataSection";
import Button from "@mui/material/Button";
import StatusTags from "../../common/StatusTags";

const ListActions = ({
  categoryId,
  productTemplateId,
}: {
  productTemplateId: string;
  categoryId: string;
}) => {
  const navigate = useNavigate();
  return (
    <TopToolbar>
      <CreateButton
        onClick={(e) => {
          e.preventDefault();
          navigate(
            `/ecom_categories/${categoryId}/ecom_product_templates/${productTemplateId}/ecom_products/create`
          );
        }}
      />
    </TopToolbar>
  );
};
const EditButton = ({
  categoryId,
  productTemplateId,
}: {
  productTemplateId: string;
  categoryId: string;
}) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(
          `/ecom_categories/${categoryId}/ecom_product_templates/${productTemplateId}/ecom_products/${record.id}/edit`
        );
      }}
    >
      Edit
    </Button>
  );
};
const ProductList = () => {
  const { categoryId, productTemplateId } = useParams();
  const navigate = useNavigate();
  if (!productTemplateId) {
    return <LoadingSection />;
  }
  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      resource={"ecom_products"}
      actions={
        <ListActions
          productTemplateId={productTemplateId}
          categoryId={productTemplateId}
        />
      }
      empty={
        <NoDataSection
          resource={"ecom_products"}
          onCreate={() => {
            navigate(
              `/ecom_categories/${categoryId}/ecom_product_templates/${productTemplateId}/ecom_products/create`
            );
          }}
        />
      }
      filter={{
        product_template_id: productTemplateId,
      }}
    >
      <Datagrid>
        <ThumbnailField multiple={true} source="images" />
        <TextField source="name" />
        <StatusTags source="status" label={"Status"} />
        <NumberField source="available_quantity" />
        <NumberField source="apple_amount" />
        <NumberField source="amount" />
        <NumberField source="discount" />
        <NumberField source="delivery_amount" />
        <ReferenceField
          reference={"ecom_product_templates"}
          source={"product_template_id"}
        >
          <TextField source={"name"} />
        </ReferenceField>
        {/* <ReferenceField reference={'ecom_product_templates'} source={'product_template_id'}>
                    <ReferenceField reference={'ecom_categories'} source={'category_id'}>

                        <TextField source={'name'} />
                    </ReferenceField>
                </ReferenceField> */}
        <ReferenceField reference={"batches"} source={"batch_id"}>
          <TextField source={"title"} />
        </ReferenceField>
        <NumberField source="height_in_mtr" />
        <NumberField source="width_in_mtr" />
        <NumberField source="length_in_mtr" />
        <NumberField source="weight_in_grams" />
        <FormattedDateTimeField source="created_at" />

        <EditButton
          productTemplateId={productTemplateId}
          categoryId={productTemplateId}
        />
        <DeleteWithDialogAction />
      </Datagrid>
    </List>
  );
};
export default ProductList;
